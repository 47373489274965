import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  User,
} from 'firebase/auth'
import { initializeApp, getApp, getApps, FirebaseApp } from 'firebase/app'

let app: FirebaseApp

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_SK,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSENGER_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
}

if (getApps().length) {
  app = getApp()
} else {
  app = initializeApp(firebaseConfig)
}

export const auth = getAuth(app)

export const signIn = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password)
}

export const onChange = (onChange: (user: User | null) => void) =>
  onAuthStateChanged(auth, onChange)
