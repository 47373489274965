'use client'
import Link from 'next/link'
import { forwardRef, InputHTMLAttributes } from 'react'
import { WrongIcon } from '../../icons/wrong-icon'
import { Placeholder } from '../placeholder'
import { twMerge } from 'tailwind-merge'

export type TextFieldHelper = {
  text: string
  link: string
}

enum VARIANTS {
  DEFAULT = 'default',
  SECONDARY = 'secondary',
}

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  loading?: boolean
  helper?: TextFieldHelper
  label?: string
  error?: string
  variant?: 'default' | 'secondary'
  labelClassName?: string
  extraClassName?: string
  helperClass?: string
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextFieldImpl(
    {
      loading,
      label,
      error,
      helper,
      required,
      variant = VARIANTS.DEFAULT,
      labelClassName,
      className,
      helperClass,
      ...props
    },
    ref
  ) {
    return (
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between">
          <label
            className={twMerge(
              variant === VARIANTS.DEFAULT &&
                'p-2 pl-0 text-[14px] font-semibold leading-[17px]',
              variant === VARIANTS.SECONDARY &&
                'p-2 pl-0 text-[14px] font-medium leading-[17px]',
              labelClassName
            )}
            htmlFor={props.name}>
            {!loading && label ? (
              label
            ) : (
              <Placeholder className="h-[17px] w-[90px]" />
            )}
          </label>
          {helper && (
            <div className="flex justify-end">
              {helper.link ? (
                <Link
                  href={helper.link}
                  className={twMerge(
                    'p-2 pr-0 text-[14px] font-medium leading-[17px] text-[#5368E5]',
                    helperClass
                  )}>
                  {helper.text}
                </Link>
              ) : (
                <p
                  className={twMerge(
                    'p-2 pr-0 text-[14px] font-medium leading-[17px] text-[#5368E5]',
                    helperClass
                  )}>
                  {helper.text}
                </p>
              )}
            </div>
          )}
        </div>
        {loading ? (
          <Placeholder className="h-[42px] w-full" />
        ) : (
          <input
            {...props}
            className={twMerge(
              'h-[42px] rounded-md bg-[#F7F7F7] p-2 px-[16px] py-[11px] font-sans text-[16px] placeholder-gray-500 placeholder:leading-[19px] focus:border-study',
              className,
              variant === 'secondary' && error
                ? 'border-2 border-[#DF1B41]'
                : ''
            )}
            ref={ref}
          />
        )}
        <div className="min-h-[30px]">
          {error && variant === VARIANTS.DEFAULT && (
            <div className="flex items-center gap-2">
              <WrongIcon />
              <p className="p-1 pl-0 text-[12px] text-[#DB4E56]">{error}</p>
            </div>
          )}
          {error && variant === VARIANTS.SECONDARY && (
            <div className="flex items-center gap-2">
              <p className="p-1 pl-0 text-[12px] text-[#DF1B41] font-medium">
                <span dangerouslySetInnerHTML={{ __html: error }}></span>
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }
)
