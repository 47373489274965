'use client'
import { Ninja } from '../platforms'

export const AccountLayout = ({ children }) => (
  <div className="flex h-screen w-screen justify-center bg-[#FAFAFC] pt-9 sm:pt-[132px]">
    <section className="flex w-full max-w-[480px] flex-col items-center p-2">
      <div className="mb-[48px] flex w-full px-2">
        <Ninja style={{ width: 114, height: 45 }} />
      </div>
      <div className="flex w-full">{children}</div>
    </section>
  </div>
)
